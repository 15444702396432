import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import InventoryDashboard from "./component/Dashboard/Dashboard";
import Warehouse from "./component/Warehouse/warehouse";
import WarehouseMovedDetails from "./component/Warehouse/WarehouseItemDetails/itemDetails";
import AddWarehouseMove from "./component/Warehouse/AddWarehouseMove/addWareouseMoveItems.js";
import axios from "axios";

import NotFound from "./component/not-found/not-found";
import UserLayout from "./layout/user-layout/user-layout";
import ProtectedRoute from "./routes/private-routes";
import Login from "./component/login/Login";

import InventoryCounts from "./component/Inventory-counts/InventoryCounts";
import AddInventoryCounts from "./component/Inventory-counts/AddInventoryCount/addInventoryCount";

import Materials from "./component/Material/material";
import Notification from "./component/Notification/notification";
import CostType from "./component/Cost-type/CostType";
import CompanyRestrictions from "./component/Company-Restrictions/CompanyRestrictions";

import InventoryCharges from "./component/Inventory-charges/InventoryCharges";
import InventoryChargeDetails from "./component/Inventory-charges/InventoryChargeDetail/InventoryChargeDetails";
import AddInventoryCharges from "./component/Inventory-charges/AddInventoryCharge/AddInventoryCharge";

import SupportIssues from "./component/Support-Issues/Support-Issues";
import AddSupportIssue from "./component/Support-Issues/AddSupportTicket/AddSupportTIcket.js";
import SupportTicketDetails from "./component/Support-Issues/SupportTicketDetails/SupportTicketDetails.js";

import EmployeeManagement from "./component/Employees-Management/Employees";
import AddEmployeeManagement from "./component/Employees-Management/AddEmployees/AddEmployees.js";
import EditEmployeeManagement from "./component/Employees-Management/EditEmployees/EditEmployees.js";

import BillingDetails from "./component/Billing-Details/Billing";
import InvoicesDetails from "./component/Billing-Details/invoice-details.jsx";

import UserContext from "./context/userContext";
import WarehouseContext from "./context/warehouseContext";

function App() {
  const [warehouseItemsData, setWarehouseItemsData] = useState([]);

  const account = JSON.parse(localStorage.getItem("CanoUserDetails"));

  const [warehouseCode, setWarehouseCode] = useState({
    newWarehouseCode: account?.DefaultWarehouse?.toString(),
    newCompanyCode: account?.DefaultCompany?.toString(),
  });

  const changeDefaultWarehouseApp = (newWarehouseCode, newCompanyCode) => {
    setWarehouseCode({ ...warehouseCode, newWarehouseCode, newCompanyCode });
  };

  useEffect(() => {
    axios
      .get(
        `https://celtic.azurewebsites.net/api/APIv1GetWarehouseItemsByCompany?CanoID=${account?.CanoID}`
      )
      .then((response) => {
        setWarehouseItemsData(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [account?.CanoID]);

  return (
    <div className="App">
      <GoogleOAuthProvider clientId="43282715203-n0ic33sde051nc28as9mr19c1h7jg999.apps.googleusercontent.com">
        <UserContext.Provider value={account}>
          <WarehouseContext.Provider value={warehouseCode}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <InventoryDashboard
                        warehouseItemsData={warehouseItemsData}
                      />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/inventory-counts"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <InventoryCounts
                        warehouseItemsData={warehouseItemsData}
                      />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />

              <Route
                path="/inventory-counts/new-inventory-count"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <AddInventoryCounts />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/inventory-charges/inventory-charge-item-details"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <InventoryChargeDetails />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/notification"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <Notification />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/warehouse/warehouse-item-details"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <WarehouseMovedDetails />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/warehouse"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <Warehouse warehouseItemsData={warehouseItemsData} />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/warehouse/new-warehouse-move"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <AddWarehouseMove />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/inventory-charges"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <InventoryCharges
                        warehouseItemsData={warehouseItemsData}
                      />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/inventory-charges/new-inventory-charge"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <AddInventoryCharges />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/barcodes"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <Materials warehouseItemsData={warehouseItemsData} />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/cost-type"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <CostType />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/comapny-restrictions"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <CompanyRestrictions />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/support-issues"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <SupportIssues />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/support-issues/add-support-issue"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <AddSupportIssue />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/support-issues/ticket-details"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <SupportTicketDetails />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/employees-management"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <EmployeeManagement />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/employees-management/add-employee"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <AddEmployeeManagement />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />

              <Route
                path="/employees-management/edit-employee"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <EditEmployeeManagement />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/billing-details"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <BillingDetails />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />
              <Route
                path="/billing-details/invoices-details"
                element={
                  <UserLayout
                    changeDefaultWarehouseApp={changeDefaultWarehouseApp}
                  >
                    <ProtectedRoute>
                      <InvoicesDetails />
                    </ProtectedRoute>
                  </UserLayout>
                }
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </WarehouseContext.Provider>
        </UserContext.Provider>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
