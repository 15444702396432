import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner, Modal } from "react-bootstrap";
import { useSnackbar } from "notistack";

import axios from "axios";
import Constant from "../../shared/_helpers/constants";
import BackArrow from "../../assets/images/back-arrow.svg";
import tagIcon from "../../assets/images/tag-close.svg";
import SearchIcon from "../../assets/images/Search.svg";
import UserContext from "../../context/userContext";

import "./CompanyRestrictions.css";

const CostType = () => {
  const account = useContext(UserContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState("");

  const [CompList, setCompList] = useState([]);
  const [restrictedComp, setRestrictedComp] = useState([]);

  const [apiLoading, setApiLoading] = useState(false);

  const CanoID = account.CanoID;
  const CompanyCode = account.DefaultCompany;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companylist, alreadyRestricted] = await Promise.all([
          axios.get(`${Constant.BASE_URL}/api/APIv1GetCompaniesForEmail?CanoID=${CanoID}`),
          axios.get(`${Constant.BASE_URL}/api/APIv1GetCanoAccount?CanoID=${CanoID}`),
        ]);
        const allCostType = companylist.data.data.filter((c) => c.Company_Code && c.Company_Code.trim() !== "").map((c) => c.Company_Code);
        setCompList([...new Set(allCostType)]);
        if (Array.isArray(alreadyRestricted.data.RestrictedCompanies)) {
          setRestrictedComp(alreadyRestricted.data.RestrictedCompanies);
        }
        setLoading("d-none");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [CanoID, CompanyCode]);

  const handleSelectCompany = (newTag) => {
    console.log(restrictedComp);

    if (newTag !== "") {
      if (restrictedComp.includes(newTag.trim())) {
        enqueueSnackbar(`${newTag.trim()} company already restricted`, { variant: "error", autoHideDuration: 3000 });
      } else {
        setRestrictedComp((oldArray) => [...oldArray, newTag.trim()]);
      }
    }
  };
  const removeCostyType = (value) => {
    setRestrictedComp(restrictedComp.filter((company) => company !== value));
  };

  const handleSubmitCostType = async () => {
    const { Payment } = JSON.parse(localStorage.getItem("CanoUserDetails"));
    if (Payment === false) {
      setIsModalOpen(false);
      return enqueueSnackbar("You need to purchase subscription or trial expired", { variant: "error" });
    }
    setApiLoading(true);
    const { data } = await axios.get(`${Constant.BASE_URL}/api/APIv1GetCanoAccount?CanoID=${CanoID}`);
    axios
      .post(`${Constant.BASE_URL}/api/APIv1SaveCanoAccount`, {
        ...data,
        RestrictedCompanies: restrictedComp,
      })
      .then((response) => {
        if (response.data.status === "success") {
          enqueueSnackbar(`Restricted company list updated successfully`, { variant: "success" });
        } else {
          enqueueSnackbar(response.data.message, { variant: "error" });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setIsModalOpen(false);
    setApiLoading(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="home_content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading d-flex">
              <Link to="/">
                <img src={BackArrow} alt="tag" />
              </Link>
              <h4>Company Restrictions</h4>
            </div>
          </div>
        </div>
        <div className="white-box-sec mt-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="cost-type-content">
                <p>Here are listed all the companies which you can add and remove from restricted phase</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              <div className="search-bar-two">
                <form className="search-form">
                  <div className="form-group">
                    <img src={SearchIcon} alt="search" />
                    <label>Companies </label>
                    <select className="form-control" style={{ marginTop: "12px" }} onChange={(e) => handleSelectCompany(e.target.value)}>
                      <option value="">Select company to restrict</option>
                      {CompList.map((value) => {
                        return (
                          <option value={value} key={value}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="white-box-sec mt-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="cost-type-content pt-3">
                <h5>Restricted companies</h5>
              </div>
            </div>
          </div>
          <div className="row mt-3 showCostTypes">
            <div className={`d-flex justify-content-center align-items-center ${loading}`}>
              <Spinner animation="border" role="status" style={{ color: "skyblue" }}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
            {restrictedComp.map((tag, index) => (
              <div className="col-auto cost-type-tags mb-3" key={tag}>
                <Link>
                  <span className="mx-2">{tag}</span>
                  <img className="mb-1 me-2" src={tagIcon} alt="tag" onClick={(e) => removeCostyType(tag)} />
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-between my-5">
          <div className="cancel-btn">
            <button onClick={(e) => navigate("/")} style={{ opacity: "1" }}>
              Cancel
            </button>
          </div>

          <div className="submit-btn">
            <button data-toggle="modal" onClick={openModal} style={{ opacity: "1" }}>
              Submit
            </button>
          </div>
        </div>
      </div>

      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header>
          <h6 style={{ fontWeight: "600", marginBottom: "0!important" }}>Are you Sure?</h6>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to update the restricted cost types? It takes some time so please wait for while.</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel_btn" onClick={closeModal}>
            Cancel
          </button>
          <button className="conform_btn" onClick={handleSubmitCostType}>
            {apiLoading ? <Spinner animation="border" role="status" size="sm" style={{ color: "skyblue" }}></Spinner> : "Submit"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CostType;
