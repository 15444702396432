import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";

import BackArrow from "../../../assets/images/back-arrow.svg";
import ItemIcon from "../../../assets/images/inventory-item.svg";
import SearchIcon from "../../../assets/images/Search.svg";

import "./ItemDetails.css";

const ItemDetails = () => {
  const location = useLocation();

  const [originWarehouse, setoriginWarehouse] = useState("");
  const [destinationWarehouse, setdestinationWarehouse] = useState("");
  const [ChargeBy, setChargeBy] = useState("");

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const data = location.state?.data || [];
    setUsers(data["details"]);
    setoriginWarehouse(data["originWarehouse"]);
    setdestinationWarehouse(data["destinationWarehouse"]);
    setChargeBy(data["ChargeBy"]);
    setLoading(false);
  }, [location.state?.data]);

  useEffect(() => {
const pattern = /^[a-zA-Z0-9/]*$/;
    if (search.match(pattern) === null) {
      return setFilteredData([]);
    }
    const resultData = users.filter((user) => {
      return user?.ItemName?.toLowerCase().match(search?.toLowerCase());
    });
    setFilteredData(resultData);
  }, [search, users]);

  const columns = [
    {
      name: "Item Name",
      width: "500px",

      cell: (row) => (
        <div className="d-flex align-items-center">
          <img src={ItemIcon} alt="Profile" />
          <span className="mx-2">{row?.ItemName}</span>
        </div>
      ),
    },
    {
      name: "Item Code (SKU)",
      selector: (row) => row?.ItemCode,
      sortable: true,
    },
    {
      name: "Quantity",
      width: "200px",

      selector: (row) => row?.Quantity,
      sortable: true,
    },
  ];

  // Additional styles for the DataTable component
  const tableStyles = {
    table: {
      width: "100%",
      tableLayout: "auto",
    },
  };

  return (
    <div className="home_content inner-home-content itemDetails">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading d-flex">
              <Link to="/warehouse">
                <img src={BackArrow} alt="back arrow" />
              </Link>
              <h4>Item Details</h4>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="custom-table p-3" style={{ borderRadius: "5px", background: "#FFFFFF" }}>
              <div className="row mx-1" style={{ overflowX: "auto" }}>
                <div className="col-lg-4 col-12 my-2">
                  <div className="table-inner-content detail">
                    <h5 style={{ whiteSpace: "nowrap" }}>Origin Warehouse</h5>
                    <p className="m-0" style={{ whiteSpace: "nowrap" }}>
                      {originWarehouse}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-12 my-2">
                  <div className="table-inner-content detail">
                    <h5 style={{ whiteSpace: "nowrap" }}>Destination Warehouse</h5>
                    <p className="m-0" style={{ whiteSpace: "nowrap" }}>
                      {destinationWarehouse}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-12 my-2">
                  <div className="table-inner-content detail">
                    <h5 style={{ whiteSpace: "nowrap" }}>Moved by</h5>
                    <p className="m-0" style={{ whiteSpace: "nowrap" }}>
                      {ChargeBy}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="my-5">
          <DataTable
            columns={columns}
            data={search ? filteredData : users}
            pagination
            customStyles={tableStyles}
            subHeader
            subHeaderComponent={
              <div className="d-flex justify-content-between align-item-center w-100 search_head">
                <div className="item_details">
                  <h6 style={{ fontWeight: "600" }}>Items</h6>
                </div>
                <div className="input-group w-50 mb-4" style={{ height: "45px" }}>
                  <img src={SearchIcon} className="search-icon" alt="search icon" />

                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control w-25"
                    style={{ borderRadius: "40px", paddingLeft: "40px" }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            }
            subHeaderAlign="right"
            progressPending={loading}
            progressComponent={
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status" style={{ color: "skyblue" }}>
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
