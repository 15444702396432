import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";

import axios from "axios";
import moment from "moment";
import DataTable from "react-data-table-component";

import SearchIcon from "../../assets/images/Search.svg";
import Constant from "../../shared/_helpers/constants";
import AddIcon from "../../assets/images/add-white.svg";
import ItemIcon from "../../assets/images/inventory-item.svg";
import UserContext from "../../context/userContext";

import "./inventoryCounts.css";

const InventoryCounts = ({ warehouseItemsData }) => {
  const account = useContext(UserContext);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const CanoID = account.CanoID;
  const CompanyCode = account.DefaultCompany;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const InventoryCountsAPI = await axios.get(
          `${Constant.BASE_URL}/api/APIv1GetInventoryAdjustmentHistory?CanoID=${CanoID}`
        );

        if (InventoryCountsAPI?.data?.failure !== "API returned an empty set") {
          const ItemsList = warehouseItemsData?.data?.data.reduce(
            (acc, item) => {
              acc[item["Item_Code"]] = item["Item_Description"];
              return acc;
            },
            {}
          );

          const InventoryCounts = [];
          InventoryCountsAPI.data.map((item) => {
            item["ItemName"] = ItemsList[item["ItemCode"]];
            return InventoryCounts.push(item);
          });

          setUsers(InventoryCounts);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [CanoID, CompanyCode, warehouseItemsData?.data?.data]);

  useEffect(() => {
    const pattern = /^[a-zA-Z0-9/]*$/;
    if (search.match(pattern) === null) {
      return setFilteredData([]);
    }
    const resultData = users.filter((user) => {
      return user?.ItemName?.toLowerCase().match(search?.toLowerCase());
    });
    setFilteredData(resultData);
  }, [search, users]);

  const customSelector = (row) => {
    const text = row?.Message;
    const truncatedText =
      text.length > 20 ? `${text.substring(0, 20)}...` : text;

    const tooltip = (
      <Tooltip id={`tooltip-${row?.id}`} className="custom-tooltip">
        {text}
      </Tooltip>
    );

    return (
      <OverlayTrigger overlay={tooltip} placement="bottom">
        <span>{truncatedText}</span>
      </OverlayTrigger>
    );
  };

  const columns = [
    {
      name: "Item Name",
      minWidth: "300px",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <img src={ItemIcon} alt="Profile" />
          <span className="mx-2">{row?.ItemName}</span>
        </div>
      ),
    },
    {
      name: "Company Code",
      minWidth: "200px",
      selector: (row) => row?.CompanyCode,
      sortable: true,
    },
    {
      name: "Item Code",
      minWidth: "150px",
      selector: (row) => row?.ItemCode,
      sortable: true,
    },
    {
      name: "Date",
      minWidth: "150px",
      selector: (row) =>
        moment(row["DTS"], "M/D/YYYY h:mm:ss A").format("YYYY:MM:DD"),
      sortable: true,
    },

    {
      name: "Qty. Adjusted",
      minWidth: "200px",
      selector: (row) => row?.Quantity,
      sortable: true,
    },
    {
      name: "Adjusted by",
      selector: (row) => row?.Email,
      sortable: true,
      minWidth: "350px",
    },
    {
      name: "Reason",
      selector: customSelector,
      sortable: true,
      minWidth: "150px",
    },
  ];

  return (
    <div className="container-fluid align-left inventory-count">
      <div className="page-heading">
        <h4 className="align-left top_head">Inventory Count</h4>
      </div>
      <DataTable
        columns={columns}
        data={search ? filteredData : users}
        pagination
        subHeader
        subHeaderComponent={
          <div className="d-flex justify-content-between w-100 search_head">
            <div className="input-group w-50">
              <img src={SearchIcon} className="search-icon" alt="search" />

              <input
                type="text"
                placeholder="Search"
                className="form-control w-25"
                style={{
                  borderRadius: "40px",
                  paddingLeft: "40px",
                  height: "45px",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div>
              <div className="btn-custom scan-item">
                <Link to="/inventory-counts/new-inventory-count">
                  <button>
                    <img src={AddIcon} alt="add Icon" /> Add New
                  </button>
                </Link>
              </div>
            </div>
          </div>
        }
        subHeaderAlign="left"
        progressPending={loading}
        progressComponent={
          <div className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              role="status"
              style={{ color: "skyblue" }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
      />
    </div>
  );
};

export default InventoryCounts;
