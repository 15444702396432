import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

// import { useMsal } from "@azure/msal-react";
import UserContext from "../context/userContext";

function ProtectedRoute({ children }) {
  // let { accounts } = useMsal();
  let accounts = useContext(UserContext);

  // Check if the user is logged in
  if (!accounts) {
    // If the user is not logged in, redirect to the login page
    return <Navigate to="/login" replace />;
  }

  // If the user is logged in, render the protected route
  return children;
}

export default ProtectedRoute;
