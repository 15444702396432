import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./support-details.css";

import BackArrow from "../../../assets/images/back-arrow.svg";

const InventoryChargeDetails = () => {
  const location = useLocation();

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const data = location.state?.data || [];
    console.log(data);
    setUsers(data);
  }, [location.state?.data]);

  const statusCode = {
    closed: "Closed",
    inProgress: "In Progress",
    onHold: "On Hold",
    solved: "Solved",
    Open: "Open",
  };

  return (
    <div className="home_content inner-home-content itemDetails">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading d-flex">
              <Link to="/support-issues">
                <img src={BackArrow} alt="arrow" />
              </Link>
              <h4>Support Issue</h4>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="custom-table py-2 px-3" style={{ borderRadius: "5px", background: "#FFFFFF" }}>
              <div className="row py-3">
                <div className="row mb-3">
                  <div className="col-md-6 col-auto">
                    <div className="">
                      <p className="mb-2">
                        <b>Ticket ID</b>
                      </p>
                      <span>{users["id"]}</span>
                    </div>
                  </div>
                  <div className="col-md-6 col-auto">
                    <div className="">
                      <p className="mb-2">
                        <b>Current Status</b>
                      </p>
                      <span className={users["Status"]}>{statusCode[users["Status"]]}</span>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-12">
                    <p>
                      <b>Subject</b>
                    </p>
                    <p style={{ overflowX: "auto", overflowY: "hidden" }}>{users["Subject"]}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <p>
                      <b>Description</b>
                    </p>
                    <p style={{ overflowX: "auto", overflowY: "hidden" }}>{users["Description"]}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="custom-table py-2 px-3" style={{ borderRadius: "5px", background: "#FFFFFF" }}>
              <div className="row py-3">
                <div className="row">
                  <div className="col-lg-12">
                    <h5>Note</h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <div id="tracking-pre" />
                    <div id="tracking">
                      <div className="tracking-list">
                        {users &&
                          users.CorrectiveActions &&
                          users?.CorrectiveActions.map(function (note, index, array) {
                            let lastIndex = index === array.length - 1;
                            return (
                              <div
                                className="tracking-item pb-2"
                                key={index}
                                style={{ borderLeft: lastIndex ? "none" : "3px solid #5E5E5E" }}
                              >
                                <div
                                  className={`${statusCode[note["Status"]]} tracking-icon status-intransit`}
                                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                >
                                  <p className="mt-3">{index + 1}</p>
                                  <svg
                                    className="svg-inline--fa fa-circle fa-w-16"
                                    aria-hidden="true"
                                    data-prefix="fas"
                                    data-icon="circle"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    data-fa-i2svg=""
                                  >
                                    <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" />
                                  </svg>
                                </div>

                                <div className="tracking-content">
                                  <p className="mb-0">
                                    <b>{statusCode[note["Status"]]}</b>
                                  </p>
                                  <p>{note["Action"]}</p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryChargeDetails;
