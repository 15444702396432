import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import moment from "moment";
import "./notification.css";

import Material from "../../assets/images/Material.png";
import BackArrow from "../../assets/images/back-arrow.svg";
import Constant from "../../shared/_helpers/constants";
import UserContext from "../../context/userContext";

const Notification = () => {
  const [users, setUsers] = useState([]);
  const account = useContext(UserContext);

  const CompanyCode = account.DefaultCompany;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${Constant.BASE_URL}/api/APIv1GetNotifications?CompanyCode=${CompanyCode}`);
        setUsers(Array.isArray(data) ? data.reverse() : []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [CompanyCode]);

  return (
    <div className="home_content inner-home-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading d-flex">
              <Link to="/">
                <img src={BackArrow} alt="arrow" />
              </Link>
              <h4>Notifications </h4>
            </div>
          </div>
        </div>
        {users.map((notification, index) => {
          return (
            <div className="white-box-sec mt-3" key={notification.id}>
              <div className="d-flex align-items-around noti-main" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                <div className="notification">
                  <img src={Material} alt="material Icon" />

                  <div className="noti-text mx-3">
                    <p className="m-0 pb-2 head" style={{ fontSize: "16px" }}>
                      {notification.title}
                    </p>
                    <p className="m-0" style={{ fontSize: "16px" }}>
                      {notification.reportedBy}
                    </p>
                  </div>
                </div>
                <div className="time">
                  <p style={{ fontSize: "14px" }}>{moment(notification.DTS).fromNow()}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Notification;
