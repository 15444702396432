import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import BlueBg from "../../assets/images/blue-bg.png";
import Constant from "../../shared/_helpers/constants";
import OrangeBg from "../../assets/images/orange-bg.png";
import InvenChargesVector from "../../assets/images/inven-charges-vector.svg";
import Warehouse from "../../assets/images/workhouse-to-warehouse.svg";
import UserContext from "../../context/userContext";
import InventoryWarehouseService from "../../shared/_services/inventory.service.js";

import "./Dashboard.css";

const InventoryDashboard = ({ warehouseItemsData }) => {
  const navigate = useNavigate();
  const account = useContext(UserContext);

  const [inventoryChargesCounts, setInventoryChargesCounts] = useState(0);
  const [warehouse2warehouseMovesCounts, setWarehouse2WarehouseMovesCounts] = useState(0);
  const [inventoryCounts, setInventoryCounts] = useState(0);
  const [totalMaterialsCounts, setTotalMaterialsCounts] = useState(0);

  const CanoID = account.CanoID;
  const CompanyCode = account.DefaultCompany;
  const Email = account.email;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [ICResponse, WMReponse, ICTReponse] = await Promise.all([
          axios.get(`${Constant.BASE_URL}/api/APIv1GetJobRequisitionHistory?CanoID=${CanoID}`),
          axios.get(`${Constant.BASE_URL}/api/APIv1GetWarehouseToWarehouseTransferHistory?CanoID=${CanoID}`),
          axios.get(`${Constant.BASE_URL}/api/APIv1GetInventoryAdjustmentHistory?CanoID=${CanoID}`),
        ]);

        setInventoryChargesCounts(Object.keys(InventoryWarehouseService.groupByData(ICResponse.data, "DTS")).length || 0);
        setWarehouse2WarehouseMovesCounts(Object.keys(InventoryWarehouseService.groupByData(WMReponse.data, "DTS")).length || 0);
        setInventoryCounts(ICTReponse.data.length || 0);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    setTotalMaterialsCounts(warehouseItemsData?.data?.data?.length || 0);
  }, [CanoID, CompanyCode, Email, warehouseItemsData?.data?.data?.length]);

  return (
    <div className="home_content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-12 mb-3" onClick={(e) => navigate("/inventory-charges")} style={{ cursor: "pointer" }}>
            <div className="Inventory-Charges ">
              <h3>{inventoryChargesCounts}</h3>
              <p>Warehouse to Job </p>
              <div className="img-footer">
                <img src={InvenChargesVector} className="img-fluid" alt="vector" draggable="false" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 mb-3" onClick={(e) => navigate("/warehouse")} style={{ cursor: "pointer" }}>
            <div className="Inventory-Charges ">
              <h3>{warehouse2warehouseMovesCounts}</h3>
              <p>Warehouse to warehouse Move</p>
              <div className="img-footer">
                <img src={Warehouse} className="img-fluid" alt="warehouse img" draggable="false" />
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-lg-6 col-12 mb-3" onClick={(e) => navigate("/inventory-counts")} style={{ cursor: "pointer" }}>
            <div
              className="container-fluid p-4 Inventory-Counts-orange"
              style={{
                backgroundImage: `url(${OrangeBg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "300px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <div>
                <h4>{inventoryCounts}</h4>
                <p>Inventory Counts</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 mb-3" onClick={(e) => navigate("/barcodes")} style={{ cursor: "pointer" }}>
            <div
              className="container-fluid p-4 Inventory-Counts-orange"
              style={{
                backgroundImage: `url(${BlueBg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "300px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <div>
                <h4>{totalMaterialsCounts}</h4>
                <p>Total Material</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryDashboard;
