import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Modal } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { FaBars } from "react-icons/fa";
import { googleLogout } from "@react-oauth/google";

import "popper.js";
import "bootstrap/dist/js/bootstrap";

import UserContext from "../../context/userContext";
import logout from "../../assets/images/log-out.svg";
import billing from "../../assets/images/billing.svg";
import logo from "../../assets/images/dashboardLogo.svg";
import settingIcon from "../../assets/images/settings.svg";
import materialIcon from "../../assets/images/Materials.svg";
import notification from "../../assets/images/Notification.svg";
import fileInvoiceIcon from "../../assets/images/file-invoice-dollar.png";
import creditCard from "../../assets/images/credit-card.svg";
import InventoryWarehouseService from "../../shared/_services/inventory.service.js";

const Navbar = ({ toggleSidebar, changeDefaultWarehouse }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const account = useContext(UserContext);
  const { instance } = useMsal();

  const [isOpen, setIsOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [warehouseList, setWarehouseList] = useState([]);
  const [defaultWarehouse, setDefaultWarehouse] = useState(
    account?.DefaultWarehouse + "-.-" + account?.DefaultCompany
  );
  console.log(account);
  const toggle = () => {
    setIsOpen(!isOpen);
    toggleSidebar(!isOpen);
  };

  const checkScreenSize = () => {
    if (window.innerWidth <= 768) {
      setIsOpen(false);
      toggleSidebar(false);
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
  }, []); // eslint-disable-line

  useEffect(() => {
    InventoryWarehouseService.getWarehouseListByCompany(enqueueSnackbar).then(
      (response) => {
        if (!response.error) {
          setWarehouseList(response.data);
        }
      }
    );
  }, [enqueueSnackbar]);

  const onLogout = () => {
    setShowModal(true);
  };

  const handleConfirmLogout = () => {
    if (account?.auth === "msoft") {
      localStorage.removeItem("CanoUserDetails");
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    } else {
      googleLogout();
      localStorage.removeItem("CanoUserDetails");
      window.location.href = "/login";
    }

    setShowModal(false);
  };

  const handleCancelLogout = () => {
    setShowModal(false);
  };

  const handleMaterialButtonClick = (link) => {
    navigate(link);
  };

  const handleDefaultWarehouseChange = (e) => {
    const newWarehouse = e.target.value;
    setDefaultWarehouse(newWarehouse);
    changeDefaultWarehouse(
      newWarehouse.split("-.-")[0],
      newWarehouse.split("-.-")[1]
    );
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "20px",
          boxShadow: "#eeeeee 2px 0px 0px 1px",
          position: "relative",
        }}
      >
        <div
          className="top-section"
          style={{
            minWidth: isOpen ? "220px" : "70px",
            display: "flex",
            justifyContent: isOpen ? "inherit" : "center",
            transition: "all 0.5s",
          }}
        >
          <div className="bars">
            <FaBars onClick={toggle} className="bar" />
          </div>
          <img
            alt="Logo"
            className="logo"
            style={{ display: isOpen ? "block" : "none" }}
            src={logo}
          />
        </div>
        <div className="nav-user">
          <div className="user-welcome">
            <p className="m-0">Hi, welcome {account?.FName.split(" ")[0]}</p>
          </div>
          <div className="user-welcome">
            <p className="m-0"></p>
          </div>

          <div className="truck-logout justify-content-end">
            {window.location.pathname.includes("/new-") ? (
              <select
                className="form-select me-3"
                style={{ border: "1px solid #FF4E00" }}
                value={defaultWarehouse}
                onChange={(e) => handleDefaultWarehouseChange(e)}
              >
                <option>Select Warehouse</option>
                {warehouseList.map((warehouse) => (
                  <option
                    value={
                      warehouse.Warehouse_Code.trim() +
                      "-.-" +
                      warehouse.Company_Code
                    }
                    key={warehouse.Warehouse_Code + warehouse.Company_Code}
                  >
                    {warehouse.Company_Code + " - " + warehouse.Name}
                  </option>
                ))}
              </select>
            ) : (
              <div className="d-flex align-items-center">
                <div className="dropdown">
                  <button
                    className="btn btn-secondary dropdown-toggle setting-icon"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={settingIcon} alt="setting" className="pt-1" />
                  </button>

                  <div
                    className="dropdown-menu drop-inner-content p-0 custom-dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {/* <img src={dropdownArrow} className="dropdown-top-arrow"></img> */}
                    <button
                      className="dropdown-item"
                      onClick={(event) =>
                        handleMaterialButtonClick("/barcodes")
                      }
                    >
                      <img src={materialIcon} alt="material" className="pe-2" />
                      Barcodes
                    </button>
                    <hr className="m-0" />
                    <button
                      className="dropdown-item"
                      onClick={(event) =>
                        handleMaterialButtonClick("/cost-type")
                      }
                    >
                      <img
                        src={fileInvoiceIcon}
                        alt="dropdown"
                        className="pe-2"
                      />
                      Cost Type
                    </button>
                    <hr className="m-0" />
                    <button
                      className="dropdown-item"
                      onClick={(event) =>
                        handleMaterialButtonClick("/comapny-restrictions")
                      }
                    >
                      <img src={creditCard} alt="dropdown" className="pe-2" />
                      Company Restrictions
                    </button>
                  </div>
                </div>

                <Link to="/billing-details">
                  <img src={billing} alt="Billing" className="px-2 py-1" />
                </Link>

                <Link to="/notification">
                  <img
                    src={notification}
                    alt="Notification"
                    className="pe-2 py-1"
                  />
                </Link>
              </div>
            )}

            <img
              src={logout}
              alt="logout"
              className="icon-color me-4"
              style={{ color: "#FF4E00" }}
              onClick={onLogout}
            />
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCancelLogout}>
        <Modal.Header>
          <h5 style={{ fontWeight: "600", marginBottom: "0!important" }}>
            Sign Out
          </h5>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to sign out?</p>
        </Modal.Body>

        <Modal.Footer>
          <button className="cancel_btn" onClick={handleCancelLogout}>
            Cancel
          </button>

          <button className="conform_btn" onClick={handleConfirmLogout}>
            Sign Out
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default Navbar;
