import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as Yup from "yup";

import axios from "axios";

import BackArrow from "../../../assets/images/back-arrow.svg";
import Constant from "../../../shared/_helpers/constants";
import UserContext from "../../../context/userContext";
import WarehouseContext from "../../../context/warehouseContext";

const InventoryChargeDetails = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const account = useContext(UserContext);

  const [showModal, setShowModal] = useState(false);

  const CanoID = account.CanoID;
  const warehouseCode = useContext(WarehouseContext);
  const newCompanyCode = warehouseCode["newCompanyCode"];

  const formik = useFormik({
    initialValues: {
      issueSubject: "",
      issueReason: "",
    },
    validationSchema: Yup.object({
      issueSubject: Yup.string().required("Subject is required"),
      issueReason: Yup.string().required("Reason is required"),
    }),
    onSubmit: async (values) => {
      setShowModal(true);
    },
  });

  // const handleFormSubmit = async () => {
  //   try {
  //     const response = await axios.post(`${Constant.BASE_URL}/api/APIv1SaveSupportTicket`, {
  //       CanoID,
  //       CompanyName: newCompanyCode,
  //       Type: "Support",
  //       Subject: formik.values.issueSubject,
  //       Description: formik.values.issueReason,
  //       Status: "inProgress",
  //       CorrectiveActions: [],
  //     });

  //     enqueueSnackbar(`Ticket Raised successfully`, { variant: "success" });
  //     navigate("/support-issues");
  //   } catch (error) {
  //     console.log(error);
  //     enqueueSnackbar(`${error.response.data.message}`, { variant: "error" });
  //   } finally {
  //     setShowModal(false);
  //   }
  // };

  const handleFormSubmit = async () => {
    try {
      const response = await axios.post(
        `${Constant.BASE_URL}/api/APIv1SaveSupportTicket`,
        {
          CanoID,
          CompanyName: newCompanyCode,
          Type: "Support",
          Subject: formik.values.issueSubject,
          Description: formik.values.issueReason,
          Status: "inProgress",
          CorrectiveActions: [],
        }
      );

      // Send email with ticket details
      const emailData = {
        To: "scano@canoconsultinginc.com",
        Subject: `New Support Ticket: ${formik.values.issueSubject}`,
        CC: "scano@canoconsultinginc.com",
        BCC: "scano@canoconsultinginc.com",
        Body: ` 
      Hello,
    
      I hope this email finds you well.
    
      We wanted to inform you that a new support ticket has been raised with the following details:
    
      - CanoID: ${CanoID}
      - Company Name: ${newCompanyCode}
      - Issue Subject: ${formik.values.issueSubject}
      - Issue Description: ${formik.values.issueReason}
      - Ticket Status: In Progress
    
      Kindly take the necessary action on this ticket promptly,
      Thank you for your patience and cooperation.
      `,
      };

      await axios.post(`${Constant.BASE_URL}/api/APIv1SendSMTP`, emailData);
      console.log("response", response);
      enqueueSnackbar(`Ticket Raised successfully`, { variant: "success" });
      navigate("/support-issues");
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`${error.response.data.message}`, { variant: "error" });
    } finally {
      setShowModal(false);
    }
  };

  return (
    <div className="home_content inner-home-content itemDetails">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading d-flex">
              <Link to="/support-issues">
                <img src={BackArrow} alt="arrow" />
              </Link>
              <h4>Add Support Issue</h4>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-12">
            <div
              className="custom-table py-2 px-3"
              style={{ borderRadius: "5px", background: "#FFFFFF" }}
            >
              <div className="row py-3">
                <div className="row">
                  <div className="col-lg-12">
                    <label>Subject</label>
                    <input
                      type="text"
                      maxLength={100}
                      className={`form-control ${
                        formik.errors.issueSubject &&
                        formik.touched.issueSubject
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter the subject"
                      {...formik.getFieldProps("issueSubject")}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.issueSubject}
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-12">
                    <label>Reason</label>
                    <textarea
                      className={`form-control ${
                        formik.errors.issueReason && formik.touched.issueReason
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Enter the reason"
                      rows={5}
                      {...formik.getFieldProps("issueReason")}
                    />
                    <div className="invalid-feedback">
                      {formik.errors.issueReason}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between my-5 ">
          <div className="cancel-btn">
            <Link to="/support-issues">
              <button>Cancel</button>
            </Link>
          </div>

          <div className="submit-btn">
            <button
              data-toggle="modal"
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
              style={{
                opacity:
                  formik.values.issueSubject && formik.values.issueReason
                    ? "1"
                    : "0.5",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header>
          <h6 style={{ fontWeight: "600", marginBottom: "0!important" }}>
            Are you Sure?
          </h6>
        </Modal.Header>
        <Modal.Body>
          <span style={{ fontSize: "14px" }}>
            Once you submit, you can’t revert this in the future
          </span>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel_btn" onClick={() => setShowModal(false)}>
            Cancel
          </button>
          <button className="conform_btn" onClick={handleFormSubmit}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InventoryChargeDetails;
