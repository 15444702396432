import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner, Modal } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";

import axios from "axios";
import Constant from "../../shared/_helpers/constants";
import BackArrow from "../../assets/images/back-arrow.svg";
import tagIcon from "../../assets/images/tag-close.svg";
import SearchIcon from "../../assets/images/Search.svg";
import UserContext from "../../context/userContext";

import "./CostType.css";

const CostType = () => {
  const account = useContext(UserContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState("");

  const [costType, setCostType] = useState([]);
  const [alreadyTag, setAlreadyTag] = useState({});
  const [tags, setTags] = useState([]);
  const [removedTag, setRemovedTag] = useState([]);
  const [alreadyTagCopy, setAlreadyTagCopy] = useState({});

  const [apiLoading, setApiLoading] = useState(false);

  const CanoID = account.CanoID;
  const CompanyCode = account.DefaultCompany;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companyCostType, alreadyRestricted] = await Promise.all([
          axios.get(`${Constant.BASE_URL}/api/APIv1GetCostTypesForCompany?CanoID=${CanoID}`),
          axios.get(`${Constant.BASE_URL}/api/APIv1GetRestrictedCostTypesForCompany?CanoID=${CanoID}&CompanyCode=${CompanyCode}`),
        ]);
        const allCostType = companyCostType.data.data.filter((c) => c.Cost_Type && c.Cost_Type.trim() !== "").map((c) => c.Cost_Type);
        setCostType(allCostType);

        let alreadyTagata = {};
        if (typeof alreadyRestricted?.data !== "string" && alreadyRestricted?.data.length > 0) {
          alreadyRestricted.data.forEach((c) => {
            alreadyTagata[c.CostType] = c;
          });
        }
        setAlreadyTag(alreadyTagata);
        setAlreadyTagCopy(alreadyTagata);

        setLoading("d-none");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [CanoID, CompanyCode]);

  const handleSelectCostType = (newTag) => {
    if (newTag !== "") {
      if (tags.includes(newTag.trim()) || alreadyTag.hasOwnProperty(newTag.trim())) {
        enqueueSnackbar(`${newTag.trim()} cost type already exists`, { variant: "error", autoHideDuration: 3000 });
      } else {
        setTags([...tags, newTag.trim()]);
      }
    }
  };

  const removeCostyType = (value) => {
    if (alreadyTag.hasOwnProperty(value)) {
      setRemovedTag([...removedTag, value]);
      setAlreadyTag((prevTags) => {
        const updatedTags = { ...prevTags };
        delete updatedTags[value];
        return updatedTags;
      });
    } else {
      setTags((prevTags) => {
        const newTags = prevTags.filter((tag) => tag !== value);
        return newTags;
      });
    }
  };

  const checkForAlreadyAddedCostTypes = (dataset) => {
    return dataset.filter((costType) => !alreadyTagCopy.hasOwnProperty(costType));
  };

  const checkForAgainAddedCostTypes = (dataset) => {
    const removableTages = dataset.filter((costType) => !tags.includes(costType));
    return removableTages.map((costType) => alreadyTagCopy[costType]["ID"]);
  };

  // Define the sendPostRequest function at a higher scope
  async function sendPostRequestWithDelay(key) {
    try {
      await axios.post(`${Constant.BASE_URL}/api/APIv1SaveRestrictedCostType?ID=${uuidv4()}&CompanyCode=${CompanyCode}&CostType=${key}`);
      await new Promise((resolve) => setTimeout(resolve, 3000));
    } catch (error) {
      console.error(`Request for ${key} failed:`, error);
    }
  }

  const handleSubmitCostType = async () => {
    const { Payment } = JSON.parse(localStorage.getItem("CanoUserDetails"));
    if (Payment === false) {
      setIsModalOpen(false);
      return enqueueSnackbar("You need to purchase subscription or trial expired", { variant: "error" });
    }
    setApiLoading(true);

    let finalRemovedCostTypes = checkForAgainAddedCostTypes([...new Set(removedTag)]);

    // Create an array of promises for removing cost types
    finalRemovedCostTypes.map(async (key) => {
      setTimeout(async () => {
        console.log(key);
        await axios
          .get(`${Constant.BASE_URL}/api/APIv1DeleteRestrictedCostType?ID=${key}`)
          .then((response) => {
            console.log(key + "--> removed successfully");
          })
          .catch((error) => {
            console.log(key + "--> removed error");
          });
      }, 1000);
    });

    let finalAddedCostTypes = checkForAlreadyAddedCostTypes(tags);

    for (const key of finalAddedCostTypes) {
      await sendPostRequestWithDelay(key);
    }

    // This code will only execute after all API requests have completed successfully
    setIsModalOpen(false);
    setApiLoading(false);

    enqueueSnackbar(`Cost types updated successfully`, { variant: "success", autoHideDuration: 3000 });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="home_content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-heading d-flex">
              <Link to="/">
                <img src={BackArrow} alt="tag" />
              </Link>
              <h4>Cost Type</h4>
            </div>
          </div>
        </div>
        <div className="white-box-sec mt-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="cost-type-content">
                <p>Here are listed all the restricted cost types for a company also you can add and remove any cost types.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="search-bar-two">
                <form className="search-form">
                  <div className="form-group">
                    <img src={SearchIcon} alt="search" />
                    <label>Cost Type</label>
                    <select className="form-control" style={{ marginTop: "12px" }} onChange={(e) => handleSelectCostType(e.target.value)}>
                      <option value="">Select Cost Type</option>
                      {costType.map((value) => {
                        return (
                          <option value={value} key={value}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="white-box-sec mt-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="cost-type-content pt-3">
                <h5>Restricted cost types</h5>
              </div>
            </div>
          </div>
          <div className="row mt-3 showCostTypes">
            <div className={`d-flex justify-content-center align-items-center ${loading}`}>
              <Spinner animation="border" role="status" style={{ color: "skyblue" }}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
            {Object.keys(alreadyTag).map((tag, index) => (
              <div className="col-auto cost-type-tags mb-3" key={tag}>
                <Link>
                  <span className="mx-2">{tag}</span>
                  <img className="mb-1 me-2" src={tagIcon} alt="tag" onClick={(e) => removeCostyType(tag)} />
                </Link>
              </div>
            ))}
            {tags.map((tag, index) => (
              <div className="col-auto cost-type-tags mb-3" key={tag}>
                <Link>
                  <span className="mx-2">{tag}</span>
                  <img className="mb-1 me-2" src={tagIcon} alt="tag" onClick={(e) => removeCostyType(tag)} />
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-between my-5">
          <div className="cancel-btn">
            <button onClick={(e) => navigate("/")} style={{ opacity: "1" }}>
              Cancel
            </button>
          </div>

          <div className="submit-btn">
            <button data-toggle="modal" onClick={openModal} style={{ opacity: "1" }}>
              Submit
            </button>
          </div>
        </div>
      </div>

      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header>
          <h6 style={{ fontWeight: "600", marginBottom: "0!important" }}>Are you Sure?</h6>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to update the restricted cost types? It takes some time so please wait for while.</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="cancel_btn" onClick={closeModal}>
            Cancel
          </button>
          <button className="conform_btn" onClick={handleSubmitCostType}>
            {apiLoading ? (
              <Spinner animation="border" role="status" size="sm" style={{ color: "skyblue" }}>
                {" "}
              </Spinner>
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CostType;
