import React, { useContext, useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import { confirm } from "react-confirm-box";
import { Modal, Dropdown } from "react-bootstrap";
import Scanner from "../../Barcode-Scanner/scanner";
import Autosuggest from "react-autosuggest";
import SearchIcon from "../../../assets/images/Search.svg";
import Barcode from "../../../assets/images/barcode.svg";
import UserContext from "../../../context/userContext";
import BackArrow from "../../../assets/images/back-arrow.svg";
import WarehouseContext from "../../../context/warehouseContext";
import InventoryWarehouseService from "../../../shared/_services/inventory.service.js";

import "./addInventoryCounts.css";

const AddInventoryCount = () => {
  const Navigate = useNavigate();
  const account = useContext(UserContext);
  const debounceTimeoutRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [itemDetails, setItemDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchBy, setSearchBy] = useState("Description");
  const [suggestions, setSuggestions] = useState([]);
  const [quantityOnHand, setQuantityOnHand] = useState(0);
  const [quantityAdjusted, setQuantityAdjusted] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [scanFor, setScanFor] = useState("addItem");
  const [itemDetailsForQuantity, setItemDetailsForQuantity] = useState([]);

  const warehouseCode = useContext(WarehouseContext);

  const newWarehouseCode = warehouseCode["newWarehouseCode"];
  const newCompanyCode = warehouseCode["newCompanyCode"];

  useEffect(() => {
    setItemDetails([]);
    setQuantityOnHand("");
    setQuantityAdjusted("");
    setSearchTerm("");
    setSuggestions([]);
  }, [warehouseCode]);

  const getSuggestions = (value) => {
    const inputValue = value?.trim()?.toLowerCase();
    const inputLength = inputValue?.length;

    if (inputLength === 0) return [];

    if (searchBy === "ItemCode") {
      return suggestions?.filter((item) =>
        item.Item_Code?.toLowerCase().includes(inputValue)
      );
    } else {
      return suggestions?.filter((item) =>
        item.Item_Description?.toLowerCase().includes(inputValue)
      );
    }
  };

  const onSuggestionSelected = (event, { suggestion, suggestionValue }) => {
    setItemDetails(suggestion);
    setQuantityOnHand("");
    setQuantityAdjusted("");
    setSuggestions([]); // Clear suggestions
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => (searchBy === "ItemCode" ? suggestion.Item_Code : suggestion.Item_Description);
  const renderSuggestion = (suggestion) =>
    searchBy === "ItemCode" ? (
      <div>{suggestion.Item_Description + "-" + suggestion.Item_Code}</div>
    ) : (
      <div>
        <div>{suggestion.Item_Description + "-" + suggestion.Item_Code}</div>
      </div>
    );

  const handleSearchFun = async (event, { newValue, method }) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(async () => {

      setSearchTerm(newValue.trim());
      if (method === "down" || method === "up") return;
      try {
        if (searchTerm.length > 0) {
          const response = await InventoryWarehouseService.getItemListByCompanyByDescription(
            enqueueSnackbar,
            newValue,
            newWarehouseCode,
            searchBy,
            newCompanyCode
          );

          if (!response.error) {
            const newItem = response.data;
            setItemDetailsForQuantity(response.data?.[0]);
            if (typeof newItem === "object") {
              if (Array.isArray(newItem)) {
                newItem.forEach((item) => {
                  item.Quantity_on_Hand = Math.abs(parseFloat(item.Quantity_on_Hand));
                });
                setSuggestions(newItem);
              } else {
                setSuggestions([]);
              }
            }
          }
        }
      } catch (error) {
        enqueueSnackbar("Error searching for items.", {
          variant: "error",
        });
        console.error("Error searching for items:", error);
      }
    }, 300); // 300ms debounce delay
  };

  const inputProps = {
    placeholder: `Search by ${searchBy === "ItemCode" ? "Item Code" : "Description"}`,
    value: searchTerm,
    onChange: handleSearchFun,
  };

  const handleScanBtnClick = () => {
    setItemDetails([]);
    setSearchTerm("");
    setScanFor("addItem");
    setIsModalOpen(true);
  };

  //open modal for quantity on hand
  const handleQuantityScanBtnClick = () => {
    setSearchTerm("");
    setScanFor("addQuantity");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onDetected = (result, props) => {
    setIsModalOpen(false);
    props.scanFor === "addItem" ? fetchBarcode(result.codeResult.code) : onDetectedQuantityOnHand(result);
  };

  const fetchBarcode = async (scannedBarcode) => {
    try {
      const response = await InventoryWarehouseService.getItemListByCompany(
        enqueueSnackbar,
        newWarehouseCode,
        scannedBarcode,
        newCompanyCode
      );

      if (!response.error) {
        const matchedItem = response.data.find((item) =>
          item.Barcode.some((barcodeObject) => barcodeObject.barcode.includes(scannedBarcode))
        );

        setItemDetailsForQuantity(matchedItem);
        setQuantityOnHand("");
        if (matchedItem) {
          const isItemAlreadyScanned = itemDetails.some((item) => item.Item_Code === matchedItem.Item_Code);

          if (isItemAlreadyScanned) {
            enqueueSnackbar(`Item already present.`, {
              variant: "warning",
            });
          } else {
            enqueueSnackbar(`Item found.`, {
              variant: "success",
            });

            // Ensure that Quantity_on_Hand is positive
            const quantityOnHandValue = Math.abs(parseFloat(matchedItem.Quantity_on_Hand));
            matchedItem.Quantity_on_Hand = quantityOnHandValue;
            setItemDetails(matchedItem);
          }
        } else {
          enqueueSnackbar(`Item not found.`, {
            variant: "error",
          });
        }
      }
    } catch (error) {
      enqueueSnackbar(`Error fetching item list.`, {
        variant: "error",
      });
      console.log("Error fetching item list:", error);
    }
  };

  //quantity on hand scanning function

  const onDetectedQuantityOnHand = (result) => {
    const scannedCode = result.codeResult.code; // Store the scanned barcode
    setIsModalOpen(false);
    const foundBarcode = itemDetailsForQuantity?.Barcode?.some((barcodeObject) => barcodeObject.barcode === scannedCode);

    if (foundBarcode) {
      // Increment quantityOnHand by 1 if the scanned barcode matches the item's barcode
      const value = quantityOnHand + 1;

      if (parseFloat(value) >= 0) {
        const quantityAdjustedValue = parseFloat(value) - parseFloat(itemDetailsForQuantity.Quantity_on_Hand);
        setQuantityOnHand(parseFloat(value));
        setQuantityAdjusted(quantityAdjustedValue);
        setValidationError("");
      } else {
        setQuantityOnHand(0);
        setQuantityAdjusted(itemDetailsForQuantity.Quantity_on_Hand);
      }
    } else {
      enqueueSnackbar("Scanned barcode does not match the item's barcode", {
        variant: "error",
      });
    }
  };

  const handleSubmitAndScanNewClick = async (e) => {
    const { Payment } = JSON.parse(localStorage.getItem("CanoUserDetails"));
    if (Payment === false) {
      return enqueueSnackbar("You need to purchase subscription or trial expired", { variant: "error" });
    }
    e.preventDefault();
    if (quantityOnHand === "" || quantityAdjusted === "") {
      setValidationError("Quantity fields are required");
      return;
    }

    const result = await confirm("Do you want to add new inventory adjustment?", options);
    if (result) {
      const reason = document.getElementById("reason").value;
      // Handle form submission

      let data = {
        ItemCode: itemDetails?.Item_Code || "",
        Message: reason,
        Warehouse: newWarehouseCode.trim(),
        Quantity: quantityAdjusted.toString(),
        Email: account.email,
      };
      InventoryWarehouseService.AddInventoryCount(data, enqueueSnackbar, newCompanyCode).then((response) => {
        if (response.status === "success") {
          enqueueSnackbar("Inventory Adjustment added successfully!", { variant: "success" });
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
        setQuantityOnHand("");
        setQuantityAdjusted("");
        setSuggestions([]);
        document.getElementById("reason").value = "";
      });
    }
  };

  const options = {
    render: (message, onConfirm, onCancel) => {
      return (
        <div className="conform_modal">
          <h3>Are you Sure?</h3>

          <p> {message} </p>

          <div className="conform_box_btn">
            <button className="cancel_btn" onClick={onCancel}>
              Cancel
            </button>

            <button className="conform_btn" onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </div>
      );
    },
  };

  const handleQuantityOnHandChange = (event) => {
    const { value } = event.target;
    const regex = /^\d{0,6}(\.\d{0,2})?$/;

    if (regex.test(value)) {
      setQuantityOnHand(value);
      const floatValue = value ? parseFloat(value) : parseFloat(value);
      const quantityAdjustedValue = (floatValue - parseFloat(itemDetails.Quantity_on_Hand)).toFixed(2);
      setQuantityAdjusted(quantityAdjustedValue);
      setValidationError("");
    } else {
      setValidationError("Quantity must be a number with up to 6 digits and up to 2 decimal places.");
    }
  };

  const handleFormSubmit = async (e) => {
    const { Payment } = JSON.parse(localStorage.getItem("CanoUserDetails"));
    if (Payment === false) {
      return enqueueSnackbar("You need to purchase subscription or trial expired", { variant: "error" });
    }
    e.preventDefault();
    if (quantityOnHand === "" || quantityAdjusted === "") {
      setValidationError("Quantity fields are required");
      return;
    }

    const result = await confirm(
      "Do you want to add new inventory adjustment?",

      options
    );
    if (result) {
      const reason = document.getElementById("reason").value;
      // Handle form submission

      let data = {
        ItemCode: itemDetails?.Item_Code || "",
        Message: reason,
        Warehouse: newWarehouseCode,
        Quantity: quantityAdjusted.toString(),
        Email: account.email,
      };

      InventoryWarehouseService.AddInventoryCount(data, enqueueSnackbar, newCompanyCode).then((response) => {
        if (response.status === "success") {
          enqueueSnackbar("Inventory Adjustment added successfully!", { variant: "success" });
          Navigate("/inventory-counts");
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row d-flex align-items-center justify-content-between  ">
        <div className="col-auto py-2 ">
          <div className="page-heading d-flex align-items-center">
            <Link to="/inventory-counts">
              <img src={BackArrow} alt="Back Arrow" className="mr-2" />
            </Link>

            <h4>Add Inventory Counts</h4>
          </div>
        </div>

        <div className="col-lg-7 col-12">
          <div className="row d-flex align-items-center justify-content-end">
            <div className="col-md-5 scan-item mb-2">
              <div className="d-flex search-items">
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onSuggestionSelected={onSuggestionSelected}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  focusInputOnSuggestionClick={false}
                />

                <img src={SearchIcon} className="search-icon" alt="search" />

                <Dropdown
                  onSelect={(selectedKey) => {
                    setSearchTerm("");
                    setSearchBy(selectedKey);
                  }}
                  className="selectBtn"
                >
                  <Dropdown.Toggle id="dropdown-basic">{searchBy === "ItemCode" ? "Item Code" : "Description"}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="ItemCode">Item Code</Dropdown.Item>
                    <Dropdown.Item eventKey="Description">Description</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="col-auto btn-custom scan-item mb-2">
              <button onClick={handleScanBtnClick}>
                <img src={Barcode} alt="barcode" />
                Scan Item
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3  form-main">
        <div className="form-content">
          <div className="row ">
            <div className="col-lg-6 ">
              <div className="form-group">
                <label htmlFor="name" className="mb-1">
                  Item Name <span className="text-danger">*</span>
                </label>

                <input type="text" className="form-control" id="name" placeholder="" value={itemDetails?.Item_Description || ""} readOnly />
              </div>
            </div>

            <div className="col-lg-6 mt-sm-2 mt-lg-0">
              <div className="form-group">
                <label htmlFor="inputSku" className="mb-1">
                  Item Code <span className="text-danger">*</span>
                </label>

                <input type="text" className="form-control" id="inputSku" placeholder="" value={itemDetails?.Item_Code || ""} readOnly />
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="form-group col-lg-4">
              <label htmlFor="inputQuantity" className="mb-1">
                Quantity On Hand <span className="text-danger">*</span>
              </label>
              <input type="text" className="form-control" id="inputQuantity" value={itemDetails?.Quantity_on_Hand || 0} readonly />
            </div>

            <div className="form-group col-lg-4 position_rel mt-sm-2 mt-lg-0">
              <label htmlFor="inputQuantityOnHand" className="mb-1">
                Quantity Available <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                id="inputQuantityOnHand"
                className="form-control"
                value={quantityOnHand}
                onChange={handleQuantityOnHandChange}
              />

              <div className="mng-scan">
                <div className="btn-custom">
                  <button onClick={handleQuantityScanBtnClick}>
                    <img src={Barcode} alt="barcode" /> Scan
                  </button>
                </div>
              </div>

              {validationError && <div className="error">{validationError}</div>}
            </div>

            <div className="form-group col-lg-4 mt-sm-2 mt-lg-0">
              <label htmlFor="inputQuantityAdjusted" className="mb-1">
                Quantity Adjusted <span className="text-danger">*</span>
              </label>

              <input type="text" className="form-control" id="inputQuantityAdjusted" value={quantityAdjusted} />
            </div>
          </div>

          <div className="form-group mt-4">
            <label htmlFor="reason" className="mb-1">
              Reason
            </label>

            <input type="text" className="form-control" id="reason" name="reason" placeholder="Please enter reason" />
          </div>

          <div className="row mt-4">
            <div className="col-lg-5 py-2"> </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-sm-6 d-flex  align-items-center">
            <div className="cancel-btn">
              <Link to="/inventory-counts">
                <button style={{ opacity: Object.keys(itemDetails).length ? "1" : "0.5" }}>Cancel</button>
              </Link>
            </div>
          </div>

          <div className="col-sm-6 d-flex  align-items-center justify-content-end">
            <button
              className="secondry-color"
              style={{
                marginRight: "20px",
                background: "none",
                border: "none",
                fontWeight: Object.keys(itemDetails).length ? "bold" : "",
              }}
              onClick={handleSubmitAndScanNewClick}
            >
              Submit and scan New
            </button>

            <div className="submit-btn">
              <button onClick={handleFormSubmit} style={{ opacity: Object.keys(itemDetails).length ? "1" : "0.5" }}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* ... (Modal component) */}

      {isModalOpen && (
        <Modal show={isModalOpen} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Scan Item</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Scanner isModalOpen={isModalOpen} onDetected={onDetected} scanFor={scanFor} />
          </Modal.Body>

          <Modal.Footer>
            <button className="conform_btn" onClick={handleCloseModal}>
              Stop
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default AddInventoryCount;
