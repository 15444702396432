import React, { useEffect, useState, useContext } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";
import moment from "moment";
import DataTable from "react-data-table-component";

import Constant from "../../shared/_helpers/constants";
import AddIcon from "../../assets/images/add-white.svg";
import SearchIcon from "../../assets/images/Search.svg";
import WarehouseIcon from "../../assets/images/warehouseIcon.svg";
import UserContext from "../../context/userContext";

const Warehouse = ({ warehouseItemsData }) => {
  const navigate = useNavigate();
  const account = useContext(UserContext);

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const CanoID = account.CanoID;
  const CompanyCode = account.DefaultCompany;
  const Email = account.email.replaceAll("+", "%2B");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [warehousesMovesAPI, warehouseListAPI] = await Promise.all([
          axios.get(`${Constant.BASE_URL}/api/APIv1GetWarehouseToWarehouseTransferHistory?CanoID=${CanoID}`),
          axios.get(`${Constant.BASE_URL}/api/APIv1GetWarehouseListByCompany?CanoID=${CanoID}`),
        ]);
        if (warehousesMovesAPI?.data?.failure !== "API returned an empty set") {
          const warehouses = warehouseListAPI.data.data.reduce((acc, item) => {
            acc[item["Warehouse_Code"].toString().trim()] = item["Name"].trim();
            return acc;
          }, {});

          const ItemsList = warehouseItemsData?.data?.data.reduce((acc, item) => {
            acc[item["Item_Code"].trim()] = item["Item_Description"];
            return acc;
          }, {});

          const groupedData = warehousesMovesAPI.data.reduce((result, item) => {
            const JobKey = moment(item["DTS"], "M/D/YYYY h:mm A");

            if (!result[JobKey]) {
              result[JobKey] = {
                originWarehouse: warehouses[item["Warehouse"].trim()],
                destinationWarehouse: warehouses[item["ToWarehouse"].trim()],
                CompanyCode: item["CompanyCode"],
                ChargeBy: item["Email"],
                Transaction_Date: moment(item["DTS"], "M/D/YYYY h:mm:ss A").format("YYYY:MM:DD HH:mm"),
                details: [],
              };
            }
            item["ItemName"] = ItemsList[item["ItemCode"].trim()];
            result[JobKey].details.push(item);
            return result;
          }, {});

          const groupedArray = Object.values(groupedData);
          setUsers(groupedArray);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [CanoID, CompanyCode, Email, warehouseItemsData?.data?.data]);

  const handleRowClick = (row) => {
    console.log(row);
    navigate("/warehouse/warehouse-item-details", { state: { data: row } });
  };

  useEffect(() => {
    if (search.match("^[a-zA-Z0-9]*$") == null) {
      return setFilteredData([]);
    }
    const resultData = users.filter((user) => {
      return user?.originWarehouse?.toLowerCase().match(search.toLowerCase());
    });
    setFilteredData(resultData);
  }, [search, users]);

  const columns = [
    {
      name: "Origin Warehouse",
      minWidth: "300px",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <img src={WarehouseIcon} alt="Profile" />
          <span className="mx-2">{row?.originWarehouse}</span>
        </div>
      ),
    },
    {
      name: "Destination Warehouse",
      selector: (row) => row?.destinationWarehouse,
      minWidth: "300px",
      sortable: true,
    },
    {
      name: "Company Code",
      minWidth: "200px",
      selector: (row) => row?.CompanyCode,
      sortable: true,
    },
    {
      name: "Date",
      minWidth: "200px",
      selector: (row) => row?.Transaction_Date,
      sortable: true,
    },
    {
      name: "Items",
      minWidth: "100px",
      selector: (row) => row?.details.length,
      sortable: true,
    },
    {
      name: "Moved by",
      minWidth: "300px",
      selector: (row) => row?.ChargeBy,
      sortable: true,
    },
  ];

  return (
    <div className="container-fluid align-left inventory-count warehouse">
      <div className="page-heading">
        <h4 className="align-left top_head">Warehouse Moves</h4>
      </div>
      <DataTable
        columns={columns}
        data={search ? filteredData : users}
        pagination
        onRowClicked={(row) => handleRowClick(row)}
        subHeader
        subHeaderComponent={
          <div className="d-flex justify-content-between w-100 search_head">
            <div className="input-group  w-50">
              <img src={SearchIcon} className="search-icon" alt="search icon" />

              <input
                type="text"
                placeholder="Search"
                className="form-control w-25 "
                style={{
                  borderRadius: "40px",
                  paddingLeft: "40px",
                  height: "45px",
                }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div>
              <div className="btn-custom scan-item">
                <Link to="/warehouse/new-warehouse-move">
                  <button>
                    <img src={AddIcon} alt="add Icon" /> Add New
                  </button>
                </Link>
              </div>
            </div>
          </div>
        }
        subHeaderAlign="left"
        progressPending={loading}
        progressComponent={
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" style={{ color: "skyblue" }}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
      />
    </div>
  );
};

export default Warehouse;
