import React, { useState } from "react";
import Navbar from "../navigation/navigation";
import Sidebar from "../sidebar/sidebar";
import Main from "../main/main";

const UserLayout = ({ children, changeDefaultWarehouseApp }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const changeDefaultWarehouse = (newWarehouse, newCompany) => {
    changeDefaultWarehouseApp(newWarehouse, newCompany);
  };

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen} changeDefaultWarehouse={changeDefaultWarehouse} />
      <div className={`wrapper ${isOpen ? "sidebar-open" : ""}`}>
        <Sidebar isOpen={isOpen} />
        <Main>{children}</Main>
      </div>
    </>
  );
};

export default UserLayout;
