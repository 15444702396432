import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Spinner } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import DataTable from "react-data-table-component";

import Constant from "../../shared/_helpers/constants";
import SearchIcon from "../../assets/images/Search.svg";
import EditIcon from "../../assets/images/edit.svg";
import profileIcon from "../../assets/images/user-placeholder.png";
import UserContext from "../../context/userContext";

const Materials = () => {
  const navigate = useNavigate();
  const account = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [matrials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [confirmModal, setShowConfirmModal] = useState(false);
  const [confirmModalEmail, setShowConfirmModalEmail] = useState("");

  const addNewEmployee = (event) => {
    navigate("/employees-management/add-employee");
  };

  const editEmployeePage = (row) => {
    navigate("/employees-management/edit-employee", { state: { data: row } });
  };

  const CanoID = account.CanoID;

  const getUsers = async () => {
    try {
      const { data } = await axios.get(`${Constant.BASE_URL}/api/APIv1GetCanoUsers?CanoID=${CanoID}`);
      setMaterials(data.filter((emp) => emp.UserType === "Inventory Staff" || emp.UserType === "Tech" || emp.UserType === "Hybrid User"));

      const initialCheckedState = data.reduce((acc, row) => {
        acc[row.email] = row?.Disabled === "N";
        return acc;
      }, {});
      setCheckedState(initialCheckedState);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching matrials:", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []); // eslint-disable-line

  function daysInThisMonth() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
  }

  var getRemanningDays = function () {
    var date = new Date();
    var time = new Date(date.getTime());
    time.setMonth(date.getMonth() + 1);
    time.setDate(0);
    return time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-').map(Number);
    // Month is 0-indexed in JavaScript Date, so subtract 1 from the month
    return new Date(year, month - 1, day);
};


  // Update status
  const handleConfirmUpdate = async () => {
    if (confirmModalEmail === "") return enqueueSnackbar("Please select valid employee", { variant: "error" });

    const UserDetails = await axios.post(`${Constant.BASE_URL}/api/APIv1GetUser?CanoID=${CanoID}&Email=${confirmModalEmail}`);
    setShowConfirmModal(false);

    if (UserDetails.data.Disabled === "Y") {
      const { data } = await axios.post(`${Constant.BASE_URL}/api/APIv1GetCanoAccount`, { CanoID });
      let PriceType = UserDetails.data.UserType === "Tech" ? "PricePerWorker" : "PricePer" + UserDetails.data.UserType.replace(" ", "");

      let subscriptionStatus = "";
      let companyTrialEnds = moment(data["JoiningDate"]).add(data["TrialDays"], "days").format();
      
      if (companyTrialEnds > moment().format()) {
        subscriptionStatus = moment(companyTrialEnds).format("DD-MM-YYYY");
        console.log("Trial period is active for company");
      } else {
        if (parseDate(moment().format("DD-MM-YYYY")) < parseDate(UserDetails.data.StripeSubscriptionID)) {
          subscriptionStatus = UserDetails.data.StripeSubscriptionID;
          console.log("Subscription already exist till :" + UserDetails.data.StripeSubscriptionID);
        } else if (data["StripePaymentMethodID"] !== "") {
          const saveSubscription = await axios.post(`${Constant.BASE_URL}/api/APIv1SaveStripePaymentIntentForUser`, {
            PaymentMethodID: data["StripePaymentMethodID"],
            CustomerID: data["StripeCustomerID"],
            Price: Math.round((parseInt(data[PriceType]) / daysInThisMonth()) * getRemanningDays()),
            Description: `Payment intent for updating ${UserDetails.data.email}`,
          });
          if (saveSubscription.data.status === "failure") {
            return enqueueSnackbar(`${saveSubscription.data.message}`, { variant: "error" });
          }
          subscriptionStatus = moment().endOf("month").format("DD-MM-YYYY");
        } else {
          return enqueueSnackbar(`You need to add payment method`, { variant: "error" });
        }
      }

      axios
        .post(`${Constant.BASE_URL}/api/APIv1SaveUser`, {
          ...UserDetails.data,
          Disabled: "N",
          StripeSubscriptionID: subscriptionStatus,
        })
        .then((response) => {
          setCheckedState((prevCheckedState) => ({
            ...prevCheckedState,
            [confirmModalEmail]: !prevCheckedState[confirmModalEmail],
          }));
          enqueueSnackbar(`Subscription created successfully`, { variant: "success" });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(`${err.message}`, { variant: "error" });
        });
    } else {
      axios
        .post(`${Constant.BASE_URL}/api/APIv1SaveUser`, {
          ...UserDetails.data,
          Disabled: "Y",
        })
        .then((response) => {
          if (response.data.status === "success") {
            setCheckedState((prevCheckedState) => ({
              ...prevCheckedState,
              [confirmModalEmail]: !prevCheckedState[confirmModalEmail],
            }));
            enqueueSnackbar(`Subscription paused successfully`, { variant: "success" });
          } else {
            enqueueSnackbar(response.data.message, { variant: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(`${err.message}`, { variant: "error" });
        });
    }
  };

  useEffect(() => {
    if (search.match("^[a-zA-Z0-9]*$") == null) {
      return setFilteredData([]);
    }
    const resultData = matrials.filter((user) => {
      return user?.email?.toLowerCase().match(search?.toLowerCase());
    });
    setFilteredData(resultData);
  }, [search, matrials]);

  const [checkedState, setCheckedState] = useState({});

  const handleCheckboxChange = (row) => {
    setShowConfirmModal(true);
    setShowConfirmModalEmail(row.email);
  };

  const columns = [
    {
      name: "Emp. Name",
      minWidth: "300px",
      cell: (row) => (
        <div className="d-flex align-items-center">
          <img src={profileIcon} alt="Profile" />
          <span className="mx-2">{row?.FName}</span>
        </div>
      ),
    },
    {
      name: "Emp.ID",
      minWidth: "150px",
      selector: (row) => row?.EmployeeCode || "-",
      sortable: true,
    },
    {
      name: "Email",
      minWidth: "400px",
      selector: (row) => row?.email || "-",
      sortable: true,
    },
    {
      name: "Contact No.",
      minWidth: "200px",
      selector: (row) => row?.Phone || "-",
      sortable: true,
    },
    {
      name: "Type",
      minWidth: "200px",
      cell: (row) => row?.UserType,
    },
    {
      name: "Status",
      minWidth: "100px",
      cell: (row) => (
        <>
          <div>
            <label className="switch mb-0">
              <input type="checkbox" checked={checkedState[row.email] || false} onChange={() => handleCheckboxChange(row)} />
              <span className="slider round" />
            </label>
          </div>
        </>
      ),
    },
    {
      name: "Action",
      minWidth: "100px",
      cell: (row) => (
        <div className="d-flex justify-content-center" style={{ cursor: "pointer" }}>
          <div
            onClick={() => {
              editEmployeePage(row);
            }}
          >
            <img src={EditIcon} alt="EditIcon" style={{ width: "30px", marginRight: "10px" }} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="container-fluid inventory-count">
      <div className="row">
        <div className="col-lg-12">
          <div className="page-heading d-flex">
            <h4>Employees Managment </h4>
          </div>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={search ? filteredData : matrials}
        pagination
        subHeader
        subHeaderComponent={
          <div className="d-flex justify-content-between w-100 search_head col-lg-5">
            <div className="input-group w-50">
              <img src={SearchIcon} className="search-icon" alt="search" />

              <input
                type="text"
                placeholder="Search"
                className="form-control w-25"
                style={{ borderRadius: "40px", paddingLeft: "40px" }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="col-lg-7">
              <div className="btn-custom scan-item">
                <button onClick={addNewEmployee}>+ Add New</button>
              </div>
            </div>
          </div>
        }
        subHeaderAlign="left"
        progressPending={loading}
        progressComponent={
          <div className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" style={{ color: "skyblue" }}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
      />

      <Modal
        show={confirmModal}
        onHide={(e) => {
          setShowConfirmModal(false);
        }}
        centered
      >
        <Modal.Header>
          <h6 style={{ fontWeight: "600", marginBottom: "0!important" }}>Are you Sure?</h6>
        </Modal.Header>
        <Modal.Body>
          <span style={{ fontSize: "14px" }}>
            {checkedState?.[confirmModalEmail]
              ? "Once you disable this employee subscription will automatically cancelled and no longer to use this application."
              : "If you enable employee status subscription will be charged automatically"}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel_btn"
            onClick={(e) => {
              setShowConfirmModal(false);
            }}
          >
            Cancel
          </button>
          <button className="conform_btn" onClick={handleConfirmUpdate}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Materials;
